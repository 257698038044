import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import Colors from '../styles/Colors';

import { Avatar } from "@rneui/themed";
import { Input } from "@rneui/themed";
import { AntDesign } from '@expo/vector-icons';
import { updateAccount } from '../services/account.service';
import { useContextData } from '../contexts/Auth';

const ProfileScreen = (props: any) => {
  const { authData, reloadUser, signOut, userData } = useContextData();

  const [username, SetUsername] = useState('');

  useEffect(() => {
    SetUsername(userData!.username);
    return () => {
      SetUsername('');
    };
  }, []);

  // TODO check why this is still wrong
  const changeUsernameWithUid = () => {
    const updateUser = userData;
    updateUser!.username = username;
    updateAccount(updateUser!)
      .catch((err) => console.warn(err))
      .then(() => reloadUser());
  };

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Avatar
          size="xlarge"
          rounded
          activeOpacity={0.7}
          source={require('../assets/profile.png')}
        />
      </View>
      <View style={styles.actionContainer}>
        <View style={styles.inputContainer}>
          <Input
            value={username}
            leftIcon={() => (
              <AntDesign
                name="user"
                size={28}
                color={Colors.primaryColor}
                style={{ marginRight: 20 }}
              />
            )}
            style={styles.input}
            inputContainerStyle={{
              height: 40,
              borderBottomColor: Colors.primaryColor,
            }}
            onEndEditing={() => changeUsernameWithUid()}
            onChangeText={SetUsername}
          ></Input>

          <Input
            leftIcon={() => (
              <AntDesign
                name="mail"
                size={28}
                color={Colors.primaryColor}
                style={{ marginRight: 20 }}
              />
            )}
            style={styles.input}
            inputContainerStyle={{
              height: 40,
              borderBottomColor: Colors.primaryColor,
            }}
          ></Input>

          <Input
            leftIcon={() => (
              <AntDesign
                name="lock"
                size={28}
                color={Colors.primaryColor}
                style={{ marginRight: 20 }}
              />
            )}
            style={styles.input}
            inputContainerStyle={{
              height: 40,
              borderBottomColor: Colors.primaryColor,
            }}
          ></Input>
        </View>
        <View style={styles.buttonContainer}>
          <Pressable
            style={[styles.button, { backgroundColor: Colors.primaryColor }]}
            onPress={() => signOut()}
          >
            <Text style={styles.buttonText}>Logout</Text>
          </Pressable>
          <Pressable
            style={[styles.button, { backgroundColor: Colors.secondaryColor }]}
          >
            <Text style={styles.buttonText}>Account löschen</Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: Colors.backgroundColor,
  },
  imageContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionContainer: {
    flex: 2,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputContainer: {
    flex: 1,
    width: '100%',
    justifyContent: 'flex-start',
  },
  input: {
    width: '100%',
    color: Colors.primaryColor,
    fontSize: 22,
  },
  buttonContainer: {
    width: '100%',
    height: 100,
  },
  button: {
    width: '100%',
    height: 50,
    opacity: 0.75,
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: 40,
  },
  buttonText: {
    fontSize: 18,
    fontWeight: '500',
  },
});

export default ProfileScreen;
