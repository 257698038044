import { useEffect, useState } from "react";
import {
  Dimensions,
  KeyboardAvoidingView,
  Modal,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View
} from "react-native";
import Colors from "../../styles/Colors";

const SelfCodeModal = (props: any) => {
  const [code, SetCode] = useState("");
  const [disableButton, SetDisableButton] = useState(false);

  useEffect(() => {
    SetDisableButton(code.length == 0);

    return () => {
      SetDisableButton(false);
    };
  }, [code]);

  const closeModal = () => {
    SetCode("");
    props.closeActiveModal();
  };

  const useQrCode = () => {
    props.selfCodeEntered(code);
    SetDisableButton(true);
    closeModal();
  };

  return (
    <Modal animationType="fade" transparent={true} visible={props.modalVisible}>
      <Pressable
        style={styles.backgroundFaded}
        onPress={() => closeModal()}
      ></Pressable>

      <KeyboardAvoidingView
        style={styles.modalView}
        behavior={Platform.OS === "ios" ? "position" : "position"}
      >
        <View style={styles.keyboardView}>
          <TextInput
            style={styles.textInput}
            selectionColor={Colors.primaryColor}
            placeholderTextColor={Colors.primaryColor}
            placeholder="Code"
            textAlign="center"
            value={code}
            autoCapitalize="none"
            onChangeText={SetCode}
          ></TextInput>
          <Pressable
            disabled={disableButton}
            style={
              disableButton || code == ""
                ? [styles.buttonClose, { opacity: 0.25 }]
                : styles.buttonClose
            }
            onPress={() => useQrCode()}
          >
            <Text style={styles.buttonText}>Fertig</Text>
          </Pressable>
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  keyboardView: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "space-evenly",
    backgroundColor: Colors.primaryColor,
    borderRadius: 20,

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  backgroundFaded: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "grey",
    opacity: 0.5,
  },
  modalView: {
    position: "absolute",
    top: Dimensions.get("screen").height * 0.35,
    alignSelf: "center",
    width: "80%",
    height: 200,
  },
  buttonText: {
    fontSize: 20,
    fontWeight: "600",
    color: Colors.backgroundColor,
  },
  buttonClose: {
    width: 150,
    height: 40,
    borderRadius: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 2,
    backgroundColor: Colors.fontColor,
    justifyContent: "center",
    alignItems: "center",
  },
  textInput: {
    height: 40,
    width: "80%",
    backgroundColor: "#828282",
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: "center",
    fontSize: 18,
    color: Colors.primaryColor,
  },
});

export default SelfCodeModal;
