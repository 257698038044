import { Avatar } from "@rneui/themed";
import { Pressable, StyleSheet, Text, View } from "react-native";
import Colors from "../../styles/Colors";

const SettingsCard = (props: any) => {
  const { iconUri, textContent } = props;

  return (
    <Pressable
      style={styles.iconAndTextContainer}
      onPress={() => props.nextNav()}
    >
      <View style={styles.icon}>
        <Avatar
          containerStyle={styles.avatarContainerStyle}
          rounded
          activeOpacity={0.7}
          source={iconUri}
        />
      </View>
      <View style={styles.textContainer}>
        {textContent <= 4 ?
          textContent.map((value: string, index: number) => {
            return (
              <Text key={index} numberOfLines={1} style={styles.text}>
                {value}
              </Text>
            );
          }) :
          textContent.map((value: string, index: number) => {
            if (index < 4) {
              return (
                <Text key={index} numberOfLines={1} style={styles.text}>
                  {value}
                </Text>
              );
            } else if (index === 4) {
              return (
                <Text key={index} numberOfLines={1} style={styles.text}>
                  ...
                </Text>
              );
            } else {
              return null;
            }
          })
        }
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  iconAndTextContainer: {
    borderRadius: 80,
    marginTop: 15,
    height: 145,
    flexDirection: "row",
    justifyContent: "space-between",
    borderWidth: 3,
    borderColor: Colors.secondaryColor,
  },
  icon: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
    position: "relative",
  },
  avatarContainerStyle: {
    position: "absolute",
    height: 150,
    width: 150,
    left: -5,
    top: -2,
  },
  textContainer: {
    flex: 1.2,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  text: {
    color: Colors.primaryColor,
    fontSize: 14,
    marginVertical: 5,
    fontWeight: "bold",
  },
});

export default SettingsCard;
