import { CreateHome, Home, HomeWithRoles } from '../dto/homes.dto';
import { friendscoreAPI } from './environment/axios.service';

let URI = '/homes';

export async function getHomesWithFirebaseUID(
  uid: string
): Promise<HomeWithRoles[]> {
  try {
    let res = await friendscoreAPI.get(URI + `/byfirebaseuid/${uid}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function addNewHome(createHome: CreateHome): Promise<Home> {
  try {
    let res = await friendscoreAPI.post(URI, createHome);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function getQrCode(homeId: string): Promise<string> {
  try {
    let res = await friendscoreAPI.get(URI + `/qrcode/${homeId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}

// export async function changeUsername(
//   firebaseuid: string,
//   username: string
// ): Promise<DBAccount> {
//   let res = await FrisbeeApi.put(
//     URI + `/username/${firebaseuid}/${username}`
//   ).then((res: any) => res.body);

//   if (res.err) throw res.err;

//   return res.body;
// }
