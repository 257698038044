import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer } from '@react-navigation/native';
import { Image } from 'react-native';
import Colors from '../styles/Colors';
import HomeStack from './HomeStack';
import SettingStack from './SettingStack';

const Tab = createBottomTabNavigator();

export default function BottomTab() {
  return (
    <NavigationContainer>
      <Tab.Navigator
        initialRouteName="Home"
        screenOptions={{
          tabBarShowLabel: false,
          headerShown: false,
          tabBarActiveTintColor: Colors.secondaryColor,
          tabBarInactiveTintColor: Colors.primaryColor,
          tabBarActiveBackgroundColor: Colors.backgroundColor,
          tabBarInactiveBackgroundColor: Colors.backgroundColor,
          tabBarStyle: [
            {
              borderTopColor: Colors.primaryColor,
              borderTopWidth: 2,
              backgroundColor: Colors.backgroundColor,
              height: 60
            },
          ],
        }}
      >
        <Tab.Screen
          name="Profile"
          options={{
            tabBarIcon: ({ color }) => (
              <Image
                source={require(`../assets/profile_outline_${color}.png`)}
                style={{ height: 40, width: 40 }}
              />
            ),
          }}
          component={SettingStack}
        />
        <Tab.Screen
          name="Home"
          options={{
            tabBarIcon: ({ color }) => (
              <Image
                source={require(`../assets/house_outline_${color}.png`)}
                style={{ height: 38, width: 34 }}
              />
            ),
          }}
          component={HomeStack}
        />
      </Tab.Navigator>
    </NavigationContainer>
  );
}
