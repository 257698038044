import React, { useState } from 'react';
import { View, Platform } from 'react-native';
import { WebView } from 'react-native-webview';

const AnimatedLoadingScreen = () => {
  // TODO have context loading property here
  const [loading, setLoading] = useState(false);

  const scalesPageToFit = Platform.OS === 'android';

  return (
    <>
      {loading && (
        <>
          <View
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              backgroundColor: 'grey',
              opacity: 0.5,
              zIndex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          ></View>
          <View
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              backgroundColor: 'transparent',
              zIndex: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <WebView
              scalesPageToFit={scalesPageToFit}
              bounces={false}
              scrollEnabled={false}
              originWhitelist={['*']}
              domStorageEnabled={true}
              source={require('../assets/icon-animated.svg')}
              style={{
                backgroundColor: 'transparent',
                width: 300,
                height: 300,
              }}
            />
          </View>
        </>
      )}
    </>
  );
};

export default AnimatedLoadingScreen;
