import { CreateMember, Member } from "../dto/member.dto";
import { friendscoreAPI } from "./environment/axios.service";

let URI = "/members";

export async function getMembersByHomeId(homeid: number): Promise<Member[]> {
  try {
    let res = await friendscoreAPI.get(URI + `/byhomeid/${homeid}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function getMemberByHomeIdAndFirebaseUid(homeId: number, firebaseUid: string): Promise<Member> {
  try {
    let res = await friendscoreAPI.get(URI + `/${homeId}/${firebaseUid}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function addNewMember(
  createMember: CreateMember
): Promise<Member> {
  try {
    let res = await friendscoreAPI.post(URI, createMember);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function updateMember(memberId: number): Promise<Member> {
  try {
    let res = await friendscoreAPI.put(URI + `/${memberId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function validateMember(memberId: number, validated: boolean): Promise<Member> {
  try {
    let res = await friendscoreAPI.put(URI + `/${memberId}/validate/${validated}`)
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteMember(memberId: number): Promise<void> {
  try {
    await friendscoreAPI.delete(URI + `/${memberId}`);
    return
  } catch (error) {
    throw error;
  }
}

export async function toggleGhostMode(memberId: number, ghostMode: boolean): Promise<Member> {
  try {
    let res = await friendscoreAPI.put(URI + `/${memberId}/ghostmode/${ghostMode}`)
    return res.data;
  } catch (error) {
    throw error;
  }
}
