import { useState } from 'react';
import {
  Image, Keyboard, KeyboardAvoidingView, Platform, Pressable,
  StyleSheet,
  Text,
  TextInput,
  TouchableWithoutFeedback,
  View
} from 'react-native';
import Toast from 'react-native-toast-message';
import { useContextData } from '../contexts/Auth';
import { ToastTypes } from '../dto/toast.dto';
import Colors from '../styles/Colors';
import CustomStyles from '../styles/CustomStyles';
import theme from '../styles/theme';

const LoginRegisterScreen = ({ navigation }: any) => {
  const auth = useContextData();

  const [mail, SetMail] = useState('');
  const [password, SetPassword] = useState('');
  const [isRegister, SetIsRegister] = useState(false);

  const [mailTouched, SetMailTouched] = useState(false);
  const [passwordTouched, SetPasswordTouched] = useState(false);

  const showToast = (toastType: ToastTypes) => {
    Toast.show({
      type: toastType.type,
      text1: toastType.text,
    });
  };

  const isFormValid = () => {
    if (isMailValid() && isPasswordValid()) {
      return true;
    }
    return false;
  };

  const isPasswordValid = () => {
    if (password.length >= 8) {
      return true;
    }

    return false;
  };

  const isMailValid = () => {
    const expression =
      /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return expression.test(String(mail).toLowerCase());
  };

  const loginOrRegister = async () => {
    auth.setLoading(true);
    if (isRegister) {
      auth.register(mail, password).then(res => {
        auth.setLoading(false);
        if (res) {
          navigation.navigate('HomesScreen');
          showToast({
            type: 'success',
            text: 'Register Successful',
          });
        } else {
          showToast({
            type: 'error',
            text: 'Register Failed',
          });
        }
      });
    } else {
      await auth.signIn(mail, password).then(res => {
        auth.setLoading(false);
        if (res) {
          navigation.navigate('HomesScreen');
          showToast({
            type: 'success',
            text: 'Login Successful',
          });
        } else {
          showToast({
            type: 'error',
            text: 'Login Failed',
          });
        }
      })
    }
  };

  return (
    <Pressable
      style={CustomStyles.container}
    >
      <KeyboardAvoidingView
        style={styles.inputContainer}
        behavior={Platform.OS === 'ios' ? 'position' : 'height'}
      >
        <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
          <>
        <View style={styles.logoContainer}>
          <Image source={require('../assets/logo.png')} style={styles.icon} />
        </View>

        <View style={{ height: 40, marginBottom: 15 }}></View>
        <TextInput
          autoComplete='email'
          style={
            !isMailValid() && mailTouched
              ? [
                  styles.textInput,
                  { borderWidth: 2, borderColor: theme.colors.error },
                ]
              : styles.textInput
          }
          selectionColor={Colors.primaryColor}
          placeholder="mail"
          textAlign="center"
          value={mail}
          autoCapitalize="none"
          onChangeText={SetMail}
          onTouchStart={() => SetMailTouched(true)}
        ></TextInput>
        <TextInput
          style={
            !isPasswordValid() && passwordTouched
              ? [
                  styles.textInput,
                  { borderWidth: 2, borderColor: theme.colors.error },
                ]
              : styles.textInput
          }
          autoComplete="password"
          secureTextEntry={true}
          placeholder="password"
          textAlign="center"
          value={password}
          onChangeText={SetPassword}
          onTouchStart={() => SetPasswordTouched(true)}
        ></TextInput>
          </>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
      <View style={styles.buttonContainer}>
        <Pressable
          style={
            isFormValid()
              ? styles.loginButton
              : [styles.loginButton, { opacity: 0.25 }]
          }
          disabled={!isFormValid()}
          onPress={() => loginOrRegister()}
        >
          <Text style={styles.loginText}>
            {isRegister ? 'Register' : 'Login'}
          </Text>
        </Pressable>
        <Pressable
          style={styles.changeOptionsContainer}
          onPress={() => SetIsRegister(!isRegister)}
        >
          <Text style={styles.changeOptionsText}>
            {isRegister ? 'login' : 'registrieren'}
          </Text>
        </Pressable>
      </View>
      <Pressable style={styles.forgotPassword} onPress={() => navigation.push('ForgotPasswordScreen')}>
        <Text style={{ color: Colors.primaryColor }}>Forgot password?</Text>
      </Pressable>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  icon: {
    height: 190,
    width: 190,
    resizeMode: 'contain',
  },
  logoContainer: {
    flex: 1.5,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 20,
  },
  inputContainer: {
    flex: 2,
    justifyContent: 'flex-end',
  },
  textInput: {
    height: 40,
    width: '80%',
    backgroundColor: Colors.primaryColor,
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 18,
    marginBottom: 15,
    alignSelf: 'center',
  },
  buttonContainer: {
    flex: 0.8,
    width: '80%',
    alignSelf: 'center',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  loginButton: {
    width: '50%',
    height: 40,
    backgroundColor: Colors.primaryColor,
    justifyContent: 'center',
    alignItems: 'center',

    marginTop: 20,
    borderRadius: 10,
  },
  loginText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: Colors.backgroundColor,
  },
  changeOptionsContainer: {
    marginTop: 20,
    width: '50%',
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  changeOptionsText: {
    color: Colors.primaryColor,
    fontSize: 20,
  },
  forgotPassword: {
    position: 'absolute',
    bottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
    width: 150,
    height: 20,
    alignSelf: 'center',
  }
});

export default LoginRegisterScreen;
