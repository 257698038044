import AsyncStorage from "@react-native-async-storage/async-storage";
import { logEvent } from "firebase/analytics";
import { EmailAuthProvider, linkWithCredential, sendPasswordResetEmail, signInWithCustomToken, signInWithEmailAndPassword } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import "react-native-get-random-values";
import { animals, colors, uniqueNamesGenerator } from 'unique-names-generator';
import { v4 as uuidv4 } from "uuid";
import { Account, CreateAccount } from "../dto/account.dto";
import { AuthData, ContextDataInterface } from "../dto/auth.dto";
import { analytics, auth } from "../firebase/config";
import {
  addNewUser, deleteAccount, getUserWithFirebaseUID,
  updateAccount
} from "../services/account.service";
import { getAnonUserJwt } from "../services/anon_sign_up.service";
import { friendscoreAPI } from "../services/environment/axios.service";
import { networkTest } from "../services/network_test.service";

const ContextData = createContext<ContextDataInterface>(
  {} as ContextDataInterface
);

export const ContextDataProvider = ({ children }: any) => {
  const [authData, setAuthData] = useState<AuthData>();
  const [userData, setUserData] = useState<Account>();

  const [loading, setLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [networkTestSuccess, setNetworkTestSuccess] = useState<boolean | undefined>();

  useEffect(() => {
    setInitialLoading(true);

    async function makeTest() {
      let network = await networkTest()

      if (network === 'OK') {
        setNetworkTestSuccess(true);
        loadStorageData();
      } else {
        setNetworkTestSuccess(false);
        setInitialLoading(false);
      }
    }

    makeTest()
  }, []);

  // firebase.default.auth().onIdTokenChanged(async (currentUser) => {
  //   if (currentUser) {
  //     setAuthDataStorage(await currentUser.getIdToken(), currentUser.uid);
  //   }
  // });

  async function loadStorageData(): Promise<void> {
    console.log("loadStorageData");
    try {
      await getAuthDataStorage().then(async (hasData) => {
        console.warn("hasData: ", hasData);
        if (hasData && auth.currentUser != null) {
          await auth
            .currentUser?.getIdToken()
            .then(async (res) => {
              await setAuthDataStorage(res, authData!.uid);
              const beUser = await getUserWithFirebaseUID(authData!.uid);
              await setUserDataStorage(beUser);
              setInitialLoading(false)
            });
        } else {
          await signInWithDuid();
        }
      });
    } catch (error) {
      console.log("loadStorageData error: " + error);
    }
  }

  const setUserDataStorage = async (userdata: Account): Promise<boolean> => {
    setUserData(userdata);
    await AsyncStorage.setItem("_UserData", JSON.stringify(userdata));
    return true;
  };

  const getUserDataStorage = async (): Promise<boolean> => {
    const userDataSerialized = await AsyncStorage.getItem("_UserData");
    if (userDataSerialized) {
      const _userData: Account = JSON.parse(userDataSerialized);
      setUserData(_userData);
      return true;
    }
    return false;
  };

  const setAuthDataStorage = async (
    token: string,
    uid: string
  ): Promise<boolean> => {
    const _authData: AuthData = {
      token: token,
      uid: uid,
    };
    friendscoreAPI.defaults.headers.common['authorization'] = `Bearer ${token}`;
    setAuthData(_authData);
    await AsyncStorage.setItem("_AuthData", JSON.stringify(_authData));
    return true;
  };

  const getAuthDataStorage = async (): Promise<boolean> => {
    const authDataSerialized = await AsyncStorage.getItem("_AuthData");
    if (authDataSerialized) {
      const _authData: AuthData = JSON.parse(authDataSerialized);
      setAuthData(_authData);
      friendscoreAPI.defaults.headers.common['authorization'] = `Bearer ${_authData.token}`;
      return true;
    }
    return false;
  };

  const signInWithDuid = async () => {
    let duidSerialized = await AsyncStorage.getItem("_DUID");
    let duid: string;

    if (!duidSerialized) {
      duid = uuidv4();
      await AsyncStorage.setItem("_DUID", JSON.stringify(duid));
    } else {
      duid = JSON.parse(duidSerialized);
    }

    console.log(duid + " (duid)");

    await getAnonUserJwt(duid).then(async (customJwt) => {
      console.log(customJwt + " (customJwt)");
      await signInWithCustomToken(auth, customJwt)
        .then(async (cred) => {
          console.log(cred.user!.uid + " (cred.user!.uid)");
          await cred.user!.getIdToken().then(async (firebaseJwt) => {
            friendscoreAPI.defaults.headers.common['authorization'] = `Bearer ${firebaseJwt}`;

            const beUser = await getUserWithFirebaseUID(cred!.user!.uid);

            console.log(beUser?.firebaseUid + " (beUser?.firebaseUid)");
            if (beUser?.firebaseUid?.length > 0) {
              await setAuthDataStorage(firebaseJwt, cred!.user!.uid);
              await setUserDataStorage(beUser);

              logEvent(analytics, 'login', {
                method: 'anonymous',
              });
            } else {
              const lowerCaseName: string = uniqueNamesGenerator({
                dictionaries: [colors, animals],
                style: 'capital',
                separator: '',
              });

              console.log(lowerCaseName);

              const createAccount: CreateAccount = {
                firebaseUid: cred!.user!.uid,
                username: lowerCaseName,
                privatesetBought: false,
                profileImageUrl: "",
              };

              await addNewUser(createAccount)
                .then(async (account) => {
                  await setAuthDataStorage(firebaseJwt, cred!.user!.uid);
                  await setUserDataStorage(account);

                  logEvent(analytics, 'sign_up', {
                    method: 'anonymous',
                  });
                })
                .catch(async () => {
                  console.log("Error creating user BE, deleting firebase user");
                  await deleteCurrentFirebaseUser()
                });
            }
            setInitialLoading(false);
          })
        }).catch(async (err) => console.log(`error logging in: ${err}`));
    }).catch(async (err) => console.log("error getting user: " + err));
  };

  const signIn = async (mail: string, password: string): Promise<boolean> => {
    console.warn(userData)
    if (userData!.mail === null || userData!.mail === undefined || userData!.mail === "") {
      await deleteCurrentFirebaseUser()
      await deleteAccount(authData!.uid);
    }

    try {
      return await signInWithEmailAndPassword(auth, mail, password)
        .then((response) => {
          const uid = response!.user!.uid;

          return response.user!.getIdToken().then((token) => {
            friendscoreAPI.defaults.headers.common['authorization'] = `Bearer ${token}`;

            return getUserWithFirebaseUID(uid).then(async (user) => {
              if (user) {
                await AsyncStorage.removeItem("_DUID");
                await setUserDataStorage(user);
                await setAuthDataStorage(token, uid);

                logEvent(analytics, 'login', {
                  method: 'email',
                });
                return true
              } else {
                throw new Error("no user found");
              }
            });
          });
        })
    } catch (error) {
      console.log(error)
      return false
    }
  };

  const signOut = async (): Promise<boolean> => {
    if (userData?.mail) {
      try {
        return auth
          .signOut()
          .then(async () => {
            setAuthData(undefined);
            setUserData(undefined);
            await AsyncStorage.removeItem("_DUID");
            await AsyncStorage.removeItem("_AuthData");
            await AsyncStorage.removeItem("_UserData");

            logEvent(analytics, 'logout');
            await signInWithDuid()
            return true
          });
      } catch (error) {
        console.log(error)
        return false
      }
    }
    return false
  };

  const register = async (mail: string, password: string): Promise<boolean> => {
    auth.currentUser?.reload()
    const mailProvider = EmailAuthProvider.credential(
      mail,
      password
    );

    console.log(mailProvider)
    console.log(auth.currentUser)

    try {
      if (auth.currentUser) {
        return linkWithCredential(auth.currentUser, mailProvider)
          .then(async (response) => {
            await getUserDataStorage();

            return response.user!.getIdToken().then((token) => {
              friendscoreAPI.defaults.headers.common['authorization'] = `Bearer ${token}`;

              const updateUser = userData!;
              updateUser.mail = mail;

              return updateAccount(updateUser).then(async (res) => {
                await setUserDataStorage(res);
                await setAuthDataStorage(token, res.firebaseUid);
                await AsyncStorage.removeItem("_DUID");

                logEvent(analytics, 'sign_up', {
                  method: 'email',
                });
                return true
              });
            });
          })
      } else {
        return false
      }
    } catch (error) {
      console.log(error);
      return false
    }
  };

  const resetPassword = async (mail: string): Promise<{ success: boolean, error?: string }> => {
    let response: { success: boolean, error?: string } = { success: true, error: undefined }

    await sendPasswordResetEmail(auth, mail).catch((error) => {
      response.success = false
      switch (error.code) {
        case "auth/user-not-found":
          response.error = "No user found with this email"
          break;
        case "auth/invalid-email":
          response.error = "Invalid email"
          break;
        default:
          response.error = "Something went wrong"
          break;
      }
    });

    return response
  }

  const reloadUser = async () => {
    getUserWithFirebaseUID(authData!.uid).then(() => {
      const _authData: AuthData = {
        uid: authData!.uid,
        token: authData!.token,
      };

      setAuthData(_authData);
      AsyncStorage.setItem("_AuthData", JSON.stringify(_authData));
    });
  };

  return (
    <ContextData.Provider
      value={{
        authData,
        userData,
        setLoading,
        initialLoading,
        loading,
        networkTestSuccess,
        signIn,
        signOut,
        register,
        reloadUser,
        resetPassword
      }}
    >
      {children}
    </ContextData.Provider>
  );
};

const deleteCurrentFirebaseUser = async () => {
  await auth.currentUser?.delete();
};

export function useContextData(): ContextDataInterface {
  const context = useContext(ContextData);

  if (!context) {
    throw new Error(
      "useContextData must be used within an ContextDataProvider"
    );
  }

  return context;
}
