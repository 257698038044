import { Account, CreateAccount } from '../dto/account.dto';
import { friendscoreAPI } from './environment/axios.service';

let URI = '/accounts';

export async function getUserWithFirebaseUID(uid: string): Promise<Account> {
  try {
    let res = await friendscoreAPI.get(URI + `/${uid}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function addNewUser(
  createAccount: CreateAccount
): Promise<Account> {
  try {
    let res = await friendscoreAPI.post(URI, createAccount);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function updateAccount(updateAccount: Account): Promise<Account> {
  try {
    let res = await friendscoreAPI.put(URI + `/${updateAccount.firebaseUid}`, updateAccount);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteAccount(firebaseUid: string): Promise<void> {
  try {
    await friendscoreAPI.delete(URI + `/${firebaseUid}`);
    return
  } catch (error) {
    throw error;
  }
}
