import { StyleSheet } from "react-native";
import Colors from "./Colors";

export default StyleSheet.create({
  container: {
    position: "absolute",
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: Colors.backgroundColor,
  },
  header: {
    backgroundColor: Colors.primaryColor,
    borderRadius: 16,
    fontSize: 20,
    fontWeight: "normal",
  },
  shadowBottom: {
    shadowOpacity: 0.6,
    shadowColor: "black",
    shadowOffset: {
      height: 2,
      width: 0,
    },
    shadowRadius: 5,
    elevation: 10,
  },
});
