import { useEffect, useState } from "react";
import {
  Dimensions,
  Modal,
  Pressable,
  StyleSheet,
  Text,
  View
} from "react-native";
import SvgQRCode from "react-native-qrcode-svg";
import { HomeWithRoles } from "../../dto/homes.dto";
import { getQrCode } from "../../services/homes.service";
import Colors from "../../styles/Colors";

const QrCodeModal = (props: any) => {
  const [qrcontent, SetQrcontent] = useState("");

  const { home }: { home: HomeWithRoles } = props;

  const closeModal = () => {
    props.closeActiveModal();
  };

  useEffect(() => {
    getQrCode(home?.homeId.toString()).then((code) => {
      SetQrcontent(code);
    });
  }, [home]);

  return (
    <Modal animationType="fade" transparent={true} visible={props.modalVisible}>
      <Pressable
        style={styles.backgroundFaded}
        onPress={() => closeModal()}
      ></Pressable>

      <View style={styles.modalView}>
        {qrcontent && (
          <View style={styles.content}>
          <View style={styles.container}>
            <SvgQRCode size={150} value={qrcontent} />
          </View>
          <Text style={styles.text}>{qrcontent}</Text>
        </View>
        )}
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  text: {
    position: "absolute",
    fontSize: 20,
    fontWeight: "600",
    bottom: 20,
  },
  content: {
    position: "relative",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
    borderRadius: 20,

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  container: {
    width: "60%",
    height: "60%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 20,
  },
  backgroundFaded: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "grey",
    opacity: 0.5,
  },
  modalView: {
    position: "absolute",
    top: Dimensions.get("screen").height * 0.3,
    alignSelf: "center",
    width: "80%",
    height: 300,
  },
});

export default QrCodeModal;
