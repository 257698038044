import { ChallengeSetDto, ChallengeWithLevel } from "../dto/challenges.dto";
import { friendscoreAPI } from "./environment/axios.service";

let URI = "/challenges";

export async function getChallengeSetsByHomeId(
  homeid: number
): Promise<ChallengeSetDto[]> {
  try {
    let res = await friendscoreAPI.get(URI + `/sets/forhomeid/${homeid}`)
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function getChallengeBySetId(
  setid: number,
  memberid: number
): Promise<ChallengeWithLevel> {
  try {
    let res = await friendscoreAPI.get(URI + `/sets/${setid}/member/${memberid}`)
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function getChallengeSetsByFirebaseUid(
  firebaseuid: string
): Promise<ChallengeSetDto[]> {
  try {
    let res = await friendscoreAPI.get(URI + `/sets/byfirebaseuid/${firebaseuid}`)
    return res.data;
  } catch (error) {
    throw error;
  }
}

