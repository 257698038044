import { Ionicons } from '@expo/vector-icons';
import { Avatar } from '@rneui/themed';
import { LinearGradient } from 'expo-linear-gradient';
import { logEvent } from 'firebase/analytics';
import { useEffect, useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { EventRegister } from 'react-native-event-listeners';
import Toast from 'react-native-toast-message';
import { useContextData } from '../../contexts/Auth';
import { ChallengeWithLevel } from '../../dto/challenges.dto';
import { Member } from '../../dto/member.dto';
import { ToastTypes } from '../../dto/toast.dto';
import { analytics } from '../../firebase/config';
import { getLastHoldedChallenge, getLastUnvalidatedChallenge } from '../../services/challenge_history.service';
import { validateMember } from '../../services/members.service';
import Colors from '../../styles/Colors';
import ChallengeView from '../challenge/ChallengeView';
import ValidateMemberModal from '../homescreen/ValidateMemberModal';

const ListItem = (props: any) => {
  const { userData } = useContextData();

  const [showChallenge, SetShowChallenge] = useState(false);
  const [showValidateMember, SetShowValidateMember] = useState(false);
  const [challenge, SetChallenge] = useState<ChallengeWithLevel>();

  const member: Member = props.item.item;

  const index: number = props.item.index;

  const ownHome: boolean = props.ownHome;

  const showToast = (toastTypes: ToastTypes) => {
    Toast.show({
      type: toastTypes.type,
      text1: toastTypes.text,
    });
  };

  const getBackgroundColor = (): string[] => {
    switch (index) {
      case 0:
        return [Colors.primaryColor, '#F7A04F'];
      case 1:
        return ['#F7A34F', '#FC6F52'];
      case 2:
        return ['#FC6F52', Colors.secondaryColor];
      default:
        return [Colors.backgroundColor, Colors.backgroundColor];
    }
  };

  const getFontColor = () => {
    if (index > 2) {
      return Colors.primaryColor;
    }

    return Colors.backgroundColor;
  };

  const doAction = () => {
    if (!ownHome && member.challengeHold) {
      challengeHoldAction();
    } else if (ownHome && !member.validated) {
      SetShowValidateMember(true);
    } else if (!member.challengeValidated && ownHome) {
      challengeUnvalidatedAction();
    }
  };

  useEffect(() => {
    console.log('challenge', challenge)
    if (challenge?.challenge.ID != null) {
      console.log('show challenge')
      SetShowChallenge(true);
    }
  }, [challenge])


  const challengeHoldAction = () => {
    getLastHoldedChallenge(member.ID).then((challenge) => {
      SetChallenge(challenge);
    });
  };

  const challengeUnvalidatedAction = () => {
    getLastUnvalidatedChallenge(member.ID).then((challenge) => {
      SetChallenge(challenge);
    });
  };

  const getItemContent = () => {
    return (
      <>
        {/* TODO check for clickable event only cached here */}
        {(((member.challengeHold && !ownHome && member.firebaseUid === userData!.firebaseUid) ||
          (!member.validated && ownHome) ||
          (!member.challengeValidated && ownHome && !member.challengeHold)) && (
            <>
              <View
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  backgroundColor: Colors.backgroundColor,
                  opacity: 0.6,
                  borderRadius: 40,
                }}
              ></View>
              <View
                style={{
                  zIndex: 1,
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {member.challengeHold && !ownHome && (
                  <Ionicons
                    style={{
                      fontSize: 50,
                      color: Colors.primaryColor,
                    }}
                    name="timer-outline"
                  ></Ionicons>
                )}
              {!member.validated && ownHome ? (
                <Ionicons
                  style={{
                    fontSize: 50,
                    color: Colors.primaryColor,
                  }}
                  name="md-alert"
                ></Ionicons>
              ) : !member.challengeValidated && ownHome && !member.challengeHold && (
                <Ionicons
                  style={{
                    fontSize: 50,
                    color: Colors.primaryColor,
                  }}
                    name="md-pause"
                  ></Ionicons>
              )}
              </View>
            </>
          ))}

        <View style={styles.imageContainer}>
          {!ownHome && member.ghostmode ? (
            <Avatar
              containerStyle={{
                height: 60,
                width: 60,
                borderRadius: 40,
                backgroundColor: 'grey',
              }}
              titleStyle={{ fontSize: 30 }}
              rounded
              activeOpacity={0.7}
            />
          ) : (
            <Avatar
              containerStyle={{
                height: 60,
                width: 60,
                borderRadius: 40,
                backgroundColor: 'grey',
              }}
              titleStyle={{ fontSize: 30 }}
              rounded
              activeOpacity={0.7}
              title={member.username[0]}
              source={{ uri: member.profileImageurl }}
            />
          )}
        </View>
        <View style={styles.nameAndAppearanceContainer}>
          <Text style={[styles.name, { color: getFontColor() }]}>
            {!ownHome && member.ghostmode ? 'ghostmode' : member.username}
          </Text>
          <Text style={[styles.appearance, { color: getFontColor() }]}>
            {member.visits} Besuche
          </Text>
        </View>
        <View style={styles.pointsMenuContainer}>
          <Text style={[styles.points, { color: getFontColor() }]}>
            {member.points}
          </Text>
        </View>

        {showChallenge && (
          <ChallengeView
            challengeViewVisible={showChallenge}
            user={member}
            shownChallenge={challenge!}
            onCloseModal={() => SetShowChallenge(false)}
            adminView={ownHome}
          />
        )}

        <ValidateMemberModal
          modalVisible={showValidateMember}
          name={member.username}
          closeActiveModal={() => SetShowValidateMember(false)}
          value={async (res: boolean) => await validateMember(member.ID, res)
            .then((member: Member) => {
              console.log(member)

              logEvent(analytics, 'member_validated', {
                validated: member.validated,
              });

              if (member.validated) {
                showToast({
                  type: 'success',
                  text: 'Mitglied wurde erfolgreich validiert',
                })
              } else {
                showToast({
                  type: 'error',
                  text: 'Mitglied konnte nicht validiert werden',
                })
              }
              EventRegister.emit("loadMember");
              SetShowValidateMember(false)
            })
          }
        />
      </>
    );
  };

  return (
    <View>
      <Pressable
        disabled={!ownHome && member.firebaseUid != userData!.firebaseUid}
        onPress={() => doAction()}
        style={styles.listEntry}
      >
        <Text style={styles.count}>{index + 1}</Text>
        {index <= 2 ? (
          <LinearGradient
            colors={getBackgroundColor()}
            start={[0.5, 0]}
            style={[styles.detailContainer]}
          >
            {/* TODO is this view needed? */}
            <View style={[styles.detailContainer]}>{getItemContent()}</View>
          </LinearGradient>
        ) : (
          <View
            style={[
              styles.detailContainer,
              { backgroundColor: Colors.backgroundColor },
            ]}
          >
            {getItemContent()}
          </View>
        )}
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  listEntry: {
    height: 100,
    width: '100%',
    paddingHorizontal: 20,
    paddingVertical: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  count: {
    fontSize: 20,
    fontWeight: 'bold',
    flex: 1,
    color: Colors.primaryColor,
    textAlign: 'left',
  },
  detailContainer: {
    flex: 10,
    height: '100%',
    borderRadius: 40,
    flexDirection: 'row',

    shadowColor: 'black',
    shadowOpacity: 0.5,
    shadowOffset: {
      height: 1,
      width: 0,
    },
    shadowRadius: 6,
  },
  imageContainer: {
    flex: 3,
    justifyContent: 'center',
    marginLeft: 10,
  },
  nameAndAppearanceContainer: {
    paddingVertical: 10,
    flex: 5,
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  name: {
    fontSize: 25,
    fontWeight: '600',
    color: Colors.backgroundColor,
  },
  appearance: {
    fontSize: 20,
  },
  pointsMenuContainer: {
    flex: 4,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  points: {
    fontSize: 20,
  },
});

export default ListItem;
