import { ChallengeHistory, ChallengeWithLevel } from "../dto/challenges.dto";
import { friendscoreAPI } from "./environment/axios.service";

let URI = "/challengeHistory";

export async function updateChallengeHistory(
  memberId: number,
  success: boolean,
  challengeId: number
): Promise<any> {
  try {
    let res = await friendscoreAPI.put(URI + `/${memberId}`, {
      params: {
        success: success,
        challengeId: challengeId
      }
    })
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function getLastHoldedChallenge(
  memberId: number
): Promise<ChallengeWithLevel> {
  try {
    let res = await friendscoreAPI.get(URI + `/holded/${memberId}`)
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function getLastUnvalidatedChallenge(
  memberId: number
): Promise<ChallengeWithLevel> {
  try {
    let res = await friendscoreAPI.get(URI + `/unvalidated/${memberId}`)
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function unholdChallenge(
  memberId: number,
): Promise<ChallengeHistory> {
  try {
    let res = await friendscoreAPI.put(URI + `/done/${memberId}`)
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteChallengeHistory(
  memberId: number,
): Promise<void> {
  try {
    await friendscoreAPI.delete(URI + `/${memberId}`);
    return
  } catch (error) {
    throw error;
  }
}