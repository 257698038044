import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import Colors from "../../styles/Colors";
import CustomStyles from "../../styles/CustomStyles";

const OwnHomeItem = (props: any) => {
  const { name } = props;

  return (
    <View style={[styles.container, CustomStyles.shadowBottom]}>
      <LinearGradient
        colors={[Colors.primaryColor, Colors.secondaryColor]}
        start={[0.8, 0]}
        style={[
          styles.container,
          { flexDirection: "column", justifyContent: "space-evenly" },
        ]}
      >
        <View style={styles.image_placeholder}></View>
        <Text style={[styles.text, { fontWeight: "bold" }]}>{name}</Text>
      </LinearGradient>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 230,
    width: 312,
    alignSelf: "center",
    borderRadius: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  image_placeholder: {
    width: 130,
    height: 130,
    borderRadius: 70,
    backgroundColor: "white",
  },
  text: {
    fontSize: 30,
    color: Colors.backgroundColor,
  },
});

export default OwnHomeItem;
