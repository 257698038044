import axios from 'axios';
import { friendscoreAPI } from './environment/axios.service';

export async function networkTest(): Promise<string> {
  const baseUrl = friendscoreAPI.defaults.baseURL;

  const url = baseUrl!.replace('v1', '');

  try {
    let res = await axios.get(url);
    return res.data.toString();
  } catch (error) {
    console.log(error);
    return 'error';
  }
}
