import { ChallengeSetDto } from "../dto/challenges.dto";
import { getChallengeSetsByHomeId } from "./challenges.service";

let challengeSets: ChallengeSetDto[] = [];

export const loadChallengeSetsForHomeId = (homeid: number) => {
  getChallengeSetsByHomeId(homeid).then((dbChallengeSets) => {
    challengeSets = dbChallengeSets;
  }).catch(() => {
    challengeSets = [];
  });
};

export const getChallengeSets = (): ChallengeSetDto[] => {
  return challengeSets;
};
