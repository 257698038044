import Toast from 'react-native-toast-message';
import { ContextDataProvider } from './app/contexts/Auth';
import BottomTab from './app/routes/BottomTab';
import AnimatedLoadingScreen from './app/screens/AnimatedLoadingScreen';

// TODO change all image placeholders to react native avatar
// TODO check exchanging textinput with input
// TODO check overlay instead of normal modals with custom backdrop

// FIXME add error on no backend connection
export default function App() {
  return (
    <>
      <ContextDataProvider>
        <AnimatedLoadingScreen></AnimatedLoadingScreen>
        <BottomTab></BottomTab>
      </ContextDataProvider>
      <Toast />
    </>
  );
}
