import React, { useEffect, useState } from "react";
import { Pressable, RefreshControl, StyleSheet, Text, View } from "react-native";
import { EventRegister } from "react-native-event-listeners";
import { FlatList, TextInput } from "react-native-gesture-handler";
import ListItem from "../components/homedetailscreen/ListItem";
import Colors from "../styles/Colors";
import CustomStyles from "../styles/CustomStyles";
import { Ionicons } from "@expo/vector-icons";
import { Member } from "../dto/member.dto";
import { getMembersByHomeId } from "../services/members.service";
import { loadChallengeSetsForHomeId } from "../services/challenge_set.service";
import ChooseChallengeSetModal from "../components/challenge/ChooseChallengeSetModal";
import { useContextData } from "../contexts/Auth";

const HomeDetailScreen = ({ route }: any) => {
  const { homeId, ownHome } = route.params;

  const { userData } = useContextData();

  const [refreshing, setRefreshing] = React.useState(false);

  const [member, setMember] = useState<Member>();
  const [challengeSetModalVisible, SetChallengeSetModalVisible] = useState(false)

  const [userList, setUserList] = useState<Member[]>([]);
  const [userListFiltered, setUserListFiltered] = useState<Member[]>([]);

  const loadAllMember = async () => {
    const members = await getMembersByHomeId(homeId).catch((res) => alert(res));

    members?.sort((a, b) => b.points - a.points);

    const oneself = members?.find((member) => member.firebaseUid === userData!.firebaseUid);

    setMember(oneself);

    return members;
  };

  const onRefresh = React.useCallback(() => {
    EventRegister.emit("loadMember");
  }, []);

  useEffect(() => {
    const listener = EventRegister.addEventListener("loadMember", () => {
      setRefreshing(true)
      loadAllMember().then((members) => {
        setUserList(members!);
        setUserListFiltered(members!);
        setRefreshing(false);
      }).catch((err) => {
        alert(err);
        setRefreshing(false);
      });

    });

    loadChallengeSetsForHomeId(homeId);

    EventRegister.emit("loadMember");

    return () => {
      EventRegister.removeEventListener(listener as string);
    };
  }, []);

  const searchKeychanged = (key: string) => {
    if (key.length > 0) {
      const filtered = userList.filter((res) =>
        res.username.toLowerCase().includes(key.toLowerCase())
      );
      setUserListFiltered(filtered);
    } else {
      setUserListFiltered(userList);
    }
  };

  return (
    <View style={CustomStyles.container}>
      <View style={styles.homeItemContainer}>
        <View style={styles.textInputContainer}>
          <Ionicons style={styles.searchIcon} name="search" />
          <TextInput
            style={[styles.textInput, CustomStyles.shadowBottom]}
            placeholder="Nutzername"
            placeholderTextColor={Colors.primaryColor}
            selectionColor={Colors.primaryColor}
            textAlign="left"
            clearButtonMode="while-editing"
            onChangeText={searchKeychanged}
          ></TextInput>
        </View>
        {userList?.length > 0 ? (
        <FlatList
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
          style={{ marginBottom: 60 }}
          data={userListFiltered}
          renderItem={(object) => <ListItem item={object} ownHome={ownHome} />}
          keyExtractor={(item, index) => item.ID.toString()}
          showsVerticalScrollIndicator={false}
        />
        ) : (
          <View style={{ flex: 0.7, justifyContent: "space-evenly", alignItems: "center" }}>
            <Ionicons size={76} color={Colors.primaryColor} name="md-sad"></Ionicons>
            <Text style={{ fontSize: 35, textAlign: "center", color: Colors.primaryColor }}>Oh je. Sehr leer hier. Lade doch ein paar Freunde ein</Text>
          </View>
        )}
        {!ownHome && (
          <View style={styles.checkInContainer}>
          <Pressable
            onPress={() => {
              SetChallengeSetModalVisible(true);
            }}
            style={styles.addButton}
            >
              <Text style={{ fontSize: 20, fontWeight: "600" }}>
              check in
            </Text>
          </Pressable>
          </View>
        )}
      </View>

      <ChooseChallengeSetModal
        member={member}
        modalVisible={challengeSetModalVisible}
        onCloseModal={() => SetChallengeSetModalVisible(false)}
      />
    </View>
  );
};
const styles = StyleSheet.create({
  homeItemContainer: {
    flex: 1,
  },
  checkInContainer: {
    position: "absolute",
    bottom: 20,
    width: "100%",
    display: "flex",
    height: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  addButton: {
    backgroundColor: Colors.primaryColor,
    borderRadius: 20,
    width: "40%",
    height: 50,
    paddingHorizontal: 20,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  textInputContainer: {
    height: 40,
    width: "90%",
    flexDirection: "row",
    marginVertical: 15,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  searchIcon: {
    position: "absolute",
    fontSize: 24,
    color: Colors.primaryColor,
    left: 10,
    zIndex: 1,
  },
  textInput: {
    height: "100%",
    width: "100%",
    backgroundColor: "#828282",
    borderRadius: 20,
    paddingLeft: 40,
    paddingRight: 10,
    fontSize: 18,
    color: Colors.primaryColor,
  },
});

export default HomeDetailScreen;
