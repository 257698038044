import { Entypo } from "@expo/vector-icons";
import { BarCodeScanner } from "expo-barcode-scanner";
import { logEvent } from "firebase/analytics";
import React, { useEffect, useState } from "react";
import {
  Image, Platform, Pressable, RefreshControl, SafeAreaView, ScrollView, StyleSheet,
  Text,
  View
} from "react-native";
import { EventRegister } from "react-native-event-listeners";
import Toast from 'react-native-toast-message';
import AddHomeModal from "../components/homescreen/AddHomeModal";
import GuestHomeItem from "../components/homescreen/GuestHomeItem";
import OwnHomeItem from "../components/homescreen/OwnHomeItem";
import QrCodeModal from "../components/homescreen/QrCodeModal";
import SelfCodeModal from "../components/homescreen/SelfCodeModal";
import { useContextData } from "../contexts/Auth";
import { HomeWithRoles } from "../dto/homes.dto";
import { CreateMember, Member } from "../dto/member.dto";
import { ToastTypes } from "../dto/toast.dto";
import { analytics } from "../firebase/config";
import { getHomesWithFirebaseUID } from "../services/homes.service";
import { addNewMember } from "../services/members.service";
import Colors from "../styles/Colors";
import CustomStyles from "../styles/CustomStyles";

const HomesScreen = ({ navigation }: any) => {
  const { authData } = useContextData();

  const [ownHomes, SetOwnHomes] = useState<HomeWithRoles[]>([]);
  const [guestHomes, SetGuestHomes] = useState<HomeWithRoles[]>([]);
  const [addHomeModalVisible, SetAddHomeModalVisible] = useState(false);
  const [refreshing, setRefreshing] = React.useState(false);

  const [selfCodeVisible, SetSelfCodeVisible] = useState(false);
  const [qrCodeVisible, SetqrCodeVisible] = useState(false);
  const [scannerVisible, SetScannerVisible] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [notGranted, SetNotGranted] = useState(true);

  const showToast = (toastTypes: ToastTypes) => {
    Toast.show({
      type: toastTypes.type,
      text1: toastTypes.text,
    });
  };

  const onRefresh = React.useCallback(() => {
    EventRegister.emit("loadHomes");
  }, []);

  const handleBarCodeScanned = ({ type, data }: any) => {
    SetScannerVisible(false);
    searchAndAddQrCodeHome(data);
  };

  const askForPermissionAndScan = async () => {
    if (Platform.OS === 'web') {
      setHasPermission(false);
      SetNotGranted(true);
      SetScannerVisible(false);
      return
    }

    if (hasPermission) {
      SetNotGranted(false);
      SetScannerVisible(true);
      return;
    }

    const { status } = await BarCodeScanner.requestPermissionsAsync();
    if (status === "granted") {
      SetScannerVisible(true);
      setHasPermission(true);
    } else {
      SetNotGranted(true);
    }
  };

  const getAllHomes = async () => {
    if (!authData?.uid) {
      return
    }
    setRefreshing(true);
    await getHomesWithFirebaseUID(authData!.uid)
      .then((res) => {
        var hostHomes: HomeWithRoles[] = [];
        var guestHomes: HomeWithRoles[] = [];

        res?.forEach((element) => {
          if (element.host == true || element.admin == true) {
            hostHomes.push(element);
          } else if (element.guest == true) {
            guestHomes.push(element);
          }
        });

        SetOwnHomes(hostHomes);
        SetGuestHomes(guestHomes);

        setRefreshing(false);
      })
      .catch(() => {
        setRefreshing(false);
        showToast({
          type: "error",
          text: "Error loading homes",
        })
      });
  };

  const searchAndAddQrCodeHome = async (code: string) => {
    if (scannerVisible) {
      SetScannerVisible(false);
    }

    let createMember: CreateMember = {
      firebaseUid: authData!.uid,
      qrcode: code
    }

    await addNewMember(createMember).then((member: Member) => {
      if (member.ID) {
        EventRegister.emit("loadHomes");

        logEvent(analytics, 'add_member');

        showToast({
          type: "success",
          text: "Welcome to the home!"
        });
      } else {
        showToast({
          type: "error",
          text: "Home not found"
        });
      }
    }).catch(() => {
      showToast({
        type: "error",
        text: "Home not found"
      });
    })
  };

  useEffect(() => {
    const listener = EventRegister.addEventListener("loadHomes", () => {
      getAllHomes();
    });

    EventRegister.emit("loadHomes");

    return () => {
      EventRegister.removeEventListener(listener as string);
      SetOwnHomes([]);
      SetGuestHomes([]);
    };
  }, [authData]);

  return (
    <View style={CustomStyles.container}>
      <View style={styles.homesContainer}>
        <Text style={styles.titleTop}>Mein Zuhause</Text>
        <SafeAreaView style={{ flex: 1 }}>
          <ScrollView
            contentContainerStyle={{ flex: 1 }}
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
          >
            {ownHomes.length > 0 ? (
              ownHomes.map((prop, index) => {
                return (
                  <Pressable
                    key={index}
                    onPress={() =>
                      navigation.push("HomeDetailScreen", {
                        title: prop.name,
                        homeId: prop.homeId,
                        ownHome: true,
                      })
                    }
                  >
                    <OwnHomeItem name={prop.name} />
                  </Pressable>
                );
              })
            ) : (
              <View style={styles.addHomeContainer}>
                <Pressable
                  style={styles.addHomeButton}
                  onPress={() => {
                    SetAddHomeModalVisible(true);
                  }}
                >
                  <Entypo
                    name="plus"
                    size={60}
                    color={Colors.backgroundColor}
                  />
                </Pressable>
              </View>
            )}
          </ScrollView>
        </SafeAreaView>
      </View>


      <View style={styles.guestHomes}>
        {guestHomes.length > 0 ? (
          <>
            <Text style={styles.title}>Gast</Text>

            {guestHomes.map((prop, index) => {
              return (
                <Pressable
                  key={index}
                  onPress={() =>
                    navigation.push("HomeDetailScreen", {
                      title: prop.name,
                      homeId: prop.homeId,
                      ownHome: false,
                    })
                  }
                >
                  <GuestHomeItem name={prop.name} />
                </Pressable>
              );
            })}
          </>
        ) : ownHomes.length === 0 && (
            <View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
              <Image
                source={require("../assets/Arrow.png")}
                style={{
                  position: "absolute",
                  height: 120,
                  width: 120,
                  resizeMode: "contain",
                  right: 30,
                  top: -25
                }}
              />
              <Text style={{
                color: Colors.primaryColor,
                fontSize: 18,
                width: "70%",
                marginBottom: 70,
                textAlign: "center"
              }}>Füge dein Zuhause hinzu oder checke bei deinen Freunden ein</Text>
              <Image
                source={require("../assets/Arrow.png")}
                style={{
                  position: "absolute",
                  height: 120,
                  width: 120,
                  resizeMode: "contain",
                  left: 100,
                  bottom: 50,
                  transform: [{ rotate: "200deg" }, { scaleX: -1 }]
                }}
              />
            </View>
        )}
      </View>

      {ownHomes[0] && (
        <Pressable
          key="qrcode"
          style={styles.qrcodeButton}
          onPress={() => SetqrCodeVisible(true)}
        >
          <Image
            source={require("../assets/qrcode.png")}
            style={{ height: 50, width: 50 }}
          />
        </Pressable>
      )}

      <Pressable
        key="scanner"
        style={styles.scannerButton}
        onPress={() => notGranted ? SetSelfCodeVisible(true) : askForPermissionAndScan()}
      >
        {notGranted ? (
          <Entypo
            name="plus"
            size={50}
            color={Colors.backgroundColor}
          />
        ) : (
          <Image
            source={require("../assets/camera.png")}
            style={{ height: 50, width: 50 }}
          />
        )}
      </Pressable>

      {scannerVisible && (
        <>
          <BarCodeScanner
            onBarCodeScanned={scannerVisible ? handleBarCodeScanned : undefined}
            style={StyleSheet.absoluteFillObject}
          />
          <Pressable
            key="manualEntry"
            style={styles.manualEntry}
            onPress={() => SetSelfCodeVisible(true)}
          >
            <Image
              source={require("../assets/question.png")}
              style={{ height: 32, width: 23 }}
            />
          </Pressable>
          <Pressable
            key="closeScanner"
            style={styles.closeScanner}
            onPress={() => SetScannerVisible(false)}
          >
            <Image
              source={require("../assets/close.png")}
              style={{ height: 80, width: 80 }}
            />
          </Pressable>
        </>
      )}

      <AddHomeModal
        modalVisible={addHomeModalVisible}
        closeActiveModal={() => SetAddHomeModalVisible(false)}
      ></AddHomeModal>
      <QrCodeModal
        modalVisible={qrCodeVisible}
        closeActiveModal={() => SetqrCodeVisible(false)}
        home={ownHomes[0]}
      ></QrCodeModal>
      <SelfCodeModal
        modalVisible={selfCodeVisible}
        closeActiveModal={() => SetSelfCodeVisible(false)}
        selfCodeEntered={(code: string) => searchAndAddQrCodeHome(code)}
      ></SelfCodeModal>
    </View>
  );
};

const styles = StyleSheet.create({
  titleTop: {
    marginBottom: 10,
    marginLeft: 14,
    fontSize: 24,
    fontWeight: "bold",
    color: Colors.fontColor,
  },
  title: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 14,
    fontSize: 24,
    fontWeight: "bold",
    color: Colors.fontColor,
  },
  homesContainer: {
    paddingTop: 10,
    flex: 1,
  },
  guestHomes: {
    flex: 1,
  },
  addHomeContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  addHomeButton: {
    justifyContent: "center",
    alignItems: "center",
    width: 160,
    height: 160,
    backgroundColor: Colors.primaryColor,
    borderRadius: 12,
    shadowRadius: 8,
    shadowColor: "black",
    shadowOpacity: 1,
    shadowOffset: {
      height: 4,
      width: 2,
    },
  },
  qrcodeButton: {
    position: "absolute",
    bottom: 20,
    right: 20,
    width: 80,
    height: 80,
    backgroundColor: "#F2C84C",
    borderRadius: 40,
    justifyContent: "center",
    alignItems: "center",
    shadowRadius: 8,
    shadowColor: "black",
    shadowOpacity: 1,
    shadowOffset: {
      height: 4,
      width: 2,
    },
  },
  scannerButton: {
    position: "absolute",
    bottom: 20,
    left: 20,
    width: 80,
    height: 80,
    backgroundColor: "#F2C84C",
    borderRadius: 40,
    justifyContent: "center",
    alignItems: "center",
    shadowRadius: 8,
    shadowColor: "black",
    shadowOpacity: 1,
    shadowOffset: {
      height: 4,
      width: 2,
    },
  },
  manualEntry: {
    position: "absolute",
    bottom: 20,
    right: 20,
    width: 50,
    height: 50,
    backgroundColor: "#F2C84C",
    borderRadius: 40,
    justifyContent: "center",
    alignItems: "center",
    shadowRadius: 8,
    shadowColor: "black",
    shadowOpacity: 1,
    shadowOffset: {
      height: 4,
      width: 2,
    },
  },
  closeScanner: {
    position: "absolute",
    bottom: 20,
    alignSelf: "center",
    width: 80,
    height: 80,
    justifyContent: "center",
    alignItems: "center",
    shadowRadius: 8,
    shadowColor: "black",
    shadowOpacity: 1,
    shadowOffset: {
      height: 4,
      width: 2,
    },
  },
});

export default HomesScreen;
