import '@firebase/analytics';
import '@firebase/auth';
import '@firebase/firestore';
import { Analytics, getAnalytics } from "firebase/analytics";
import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { GetEnvironment } from '../services/environment/environmentHelper';

export interface firebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId?: string;
  // databaseURL: string;
}

// FIXME put into config.js // check why
const firebaseConfigLocalPr: firebaseConfig = {
  apiKey: 'AIzaSyDf3Oig7D0QNVKa_BagUrmvxAfemzgBgzs',
  authDomain: 'friendscore-115fa.firebaseapp.com',
  projectId: 'friendscore-115fa',
  storageBucket: 'friendscore-115fa.appspot.com',
  messagingSenderId: '953902981605',
  appId: '1:953902981605:web:eceef38237ae18361a0f0c',
  measurementId: 'G-XSGSRH6NGN'
};

const firebaseConfigDev: firebaseConfig = {
  apiKey: "AIzaSyDcAJx7RunPP2RQQ6wBbmI8vzeyUY2mjYU",
  authDomain: "friendscore-stage.firebaseapp.com",
  projectId: "friendscore-stage",
  storageBucket: "friendscore-stage.appspot.com",
  messagingSenderId: "583133417399",
  appId: "1:583133417399:web:16b0a6228210d22b54a232"
};

const firebaseConfigProd: firebaseConfig = {
  apiKey: "AIzaSyD8J0sWccW2a459S_-hEh5KkNjPHIfMAcg",
  authDomain: "friendscore-prod.firebaseapp.com",
  projectId: "friendscore-prod",
  storageBucket: "friendscore-prod.appspot.com",
  messagingSenderId: "467537254389",
  appId: "1:467537254389:web:d1bcaab1a10ee043a572bc"
};

const env = GetEnvironment()

let config: firebaseConfig = {} as firebaseConfig;
if (env === 'local' || env === 'pr') {
  console.log('firebase: local pr');
  config = firebaseConfigLocalPr;
} else if (env === 'develop') {
  console.log('firebase: dev');
  config = firebaseConfigDev;
} else if (env === 'prod') {
  console.log('firebase: prod');
  config = firebaseConfigProd;
} else {
  throw new Error('Environment is not defined');
}

let firebase = initializeApp(config);
let auth = getAuth(firebase)

let analytics: Analytics = undefined as any as Analytics;
if (config.measurementId != undefined) {
  analytics = getAnalytics(firebase);
}

export { firebase, auth, analytics };

