import { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import SettingsCard from '../components/settings/SettingsCard';
import { useContextData } from '../contexts/Auth';
import { ChallengeSetDto } from '../dto/challenges.dto';
import { getChallengeSetsByFirebaseUid } from '../services/challenges.service';
import Colors from '../styles/Colors';

const SettingsScreen = ({ navigation }: any) => {
  const { userData } = useContextData();

  const [profile, SetProfile] = useState<any[]>([]);
  const [sets, setSets] = useState<string[]>([]);

  useEffect(() => {
    SetProfile([userData?.username, userData?.mail]);

    getChallengeSetsByFirebaseUid(userData!.firebaseUid).then((challengeSets: ChallengeSetDto[]) => {
      setSets(challengeSets.map((challengeSet: ChallengeSetDto) => challengeSet.name));
    }).catch((err: any) => console.log(err))

    return () => {
      setSets([]);
      SetProfile([]);
    };
  }, [userData]);

  return (
    <View style={styles.container}>
      <View style={styles.settingContainer}>
        <View style={styles.headingContainer}>
          <Text style={styles.heading}>Dein Profil</Text>
        </View>
        <SettingsCard
          iconUri={require('../assets/profile.png')}
          nextNav={() => navigation.push('ProfileScreen')}
          textContent={profile}
        ></SettingsCard>
      </View>
      <View style={styles.settingContainer}>
        <View style={styles.headingContainer}>
          <Text style={styles.heading}>Dein Zuhause</Text>
        </View>
        <SettingsCard
          iconUri={require('../assets/house.png')}
          nextNav={() => {}}
          textContent={profile}
        ></SettingsCard>
      </View>
      <View style={styles.settingContainer}>
        <View style={styles.headingContainer}>
          <Text style={styles.heading}>Deine Challengesets</Text>
        </View>
        <SettingsCard
          iconUri={require('../assets/challenge-icon-invert.png')}
          nextNav={() => navigation.push('SetsScreen')}
          textContent={sets}
        ></SettingsCard>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: Colors.backgroundColor,
    paddingHorizontal: 20,
  },
  settingContainer: {
    flex: 1,
    marginTop: 10,
  },
  headingContainer: {
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  heading: {
    paddingHorizontal: 15,
    borderRadius: 10,
    borderColor: Colors.primaryColor,
    borderWidth: 1,
    color: Colors.primaryColor,
    backgroundColor: Colors.backgroundColor,
    fontSize: 16,
    fontWeight: 'bold',
  },
});

export default SettingsScreen;
