import { AntDesign } from '@expo/vector-icons';
import { createStackNavigator } from '@react-navigation/stack';
import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import { useEffect, useState } from 'react';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import Toast from 'react-native-toast-message';
import { useContextData } from '../contexts/Auth';
import { Member } from '../dto/member.dto';
import { ToastTypes } from '../dto/toast.dto';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import HomeDetailScreen from '../screens/HomeDetailScreen';
import HomesScreen from '../screens/HomesScreen';
import LoginRegisterScreen from '../screens/LoginRegisterScreen';
import { getMemberByHomeIdAndFirebaseUid, toggleGhostMode } from '../services/members.service';
import Colors from '../styles/Colors';
import CustomStyles from '../styles/CustomStyles';

const Stack = createStackNavigator();

SplashScreen.preventAutoHideAsync();

const HomeStack = () => {
  const { userData, signOut } = useContextData();
  const [member, setMember] = useState<Member>();

  const { initialLoading, networkTestSuccess } = useContextData();

  useEffect(() => {
    if (!initialLoading) {
      SplashScreen.hideAsync();
    }
  }, [initialLoading]);

  useEffect(() => {
    if (networkTestSuccess === false) {
      showToast({
        type: 'error',
        text: 'no network connection',
      });
    }
  }, [networkTestSuccess]);


  const showToast = (toastType: ToastTypes) => {
    Toast.show({
      type: toastType.type,
      text1: toastType.text,
    });
  };

  const toggleGhostModeAction = async (homeId: number) => {
    try {
      let loaded_member = await getMemberByHomeIdAndFirebaseUid(homeId, userData!.firebaseUid)
      setMember(loaded_member)
      toggleGhostMode(loaded_member.ID, !loaded_member.ghostmode)
      .then((member: Member) => {
        showToast({
          type: "success",
          text: member.ghostmode ? "Ghostmode activated" : "Ghostmode deactivated",
        })
      })
    } catch (error) {
      console.log(error);
        showToast({
          type: "error",
          text: "Error toggle ghostmode",
        });
    }
  }

  return (
    // FIXME show screen when no internet access with button to reload
    <>
      {!initialLoading &&
        <Stack.Navigator
          screenOptions={{
            headerStyle: {
              backgroundColor: CustomStyles.container.backgroundColor,
              shadowOpacity: 0,
              elevation: 0,
            },
            headerTitleStyle: {
              fontWeight: CustomStyles.header.fontWeight,
              fontSize: CustomStyles.header.fontSize,
            },
            headerTitleAlign: 'left',
            headerBackTitleVisible: false,
            headerTintColor: Colors.primaryColor,
            headerRightContainerStyle: {
              paddingRight: 20,
            },
          }}
        >
          <Stack.Screen
            name="HomesScreen"
            component={HomesScreen}
            options={({ navigation }) => ({
              headerTitle: () => (
                <View style={{ flex: 1, justifyContent: "center" }}>
                  <Text style={styles.mainTitleBold}>
                    friend
                    <Text style={styles.mainTitleNormal}>score</Text>
                  </Text>
                </View>
              ),
              headerRight: () => (
                !userData?.mail ? (
                  <Pressable
                    style={{ flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "center" }}
                    onPress={() => navigation.push('LoginRegisterScreen')}
                  >
                    <Text style={{ color: Colors.primaryColor, paddingRight: 10, fontSize: 18 }}>Login</Text>
                    <AntDesign
                      name="login"
                      size={24}
                      color={Colors.primaryColor}
                    />
                  </Pressable>
                ) : (
                  <Pressable
                      style={{ flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "center" }}
                    onPress={() => signOut().then((loggedOut: boolean) => {
                      if (loggedOut) {
                        showToast({
                          type: "success",
                          text: "Logged out successful",
                        });
                      } else {
                        showToast({
                          type: "error",
                          text: "Error logging out",
                        });
                      }
                    }
                    )}
                  >
                    <Text style={{ color: Colors.primaryColor, paddingRight: 10, fontSize: 18 }}>Logout</Text>
                    <AntDesign
                      name="logout"
                      size={24}
                      color={Colors.primaryColor}
                    />
                  </Pressable>
                )),
            })}
          />
          <Stack.Screen
            name="HomeDetailScreen"
            component={HomeDetailScreen}
            options={({ route }: any) => ({
              title: route.params.title,
              headerTitleStyle: {
                fontSize: 26,
              },
              headerLeftContainerStyle: {
                paddingLeft: 20,
              },
              headerRight: () => {
                return route.params.ownHome === false ? (
                  <Pressable
                    style={{ flex: 1, flexDirection: "row", alignItems: "center" }}
                    onPress={() => toggleGhostModeAction(route.params.homeId)}
                  >
                    {member?.ghostmode ? (
                      <Image
                        source={require('../assets/active.png')}
                        style={{ height: 28, width: 28 }}
                      />) : (
                      <Image
                        source={require('../assets/inactive.png')}
                        style={{ height: 28, width: 28 }}
                      />)
                    }
                  </Pressable>
                ) : null
              },
            })}
          />
          <Stack.Screen
            name="LoginRegisterScreen"
            component={LoginRegisterScreen}
            options={() => ({
              title: "Login",
              headerTitleStyle: {
                fontSize: 26,
              },
              headerLeftContainerStyle: {
                paddingLeft: 20,
              },
            })}
          />
          <Stack.Screen
            name="ForgotPasswordScreen"
            component={ForgotPasswordScreen}
            options={() => ({
              title: "Forgot password",
              headerTitleStyle: {
                fontSize: 26,
              },
              headerLeftContainerStyle: {
                paddingLeft: 20,
              },
            })}
          />
        </Stack.Navigator>
      }
      <StatusBar backgroundColor={Colors.primaryColor} style="auto" />
    </>
  );
};

const styles = StyleSheet.create({
  mainTitleBold: {
    color: Colors.primaryColor,
    fontSize: 30,
    fontWeight: '600',
  },
  mainTitleNormal: {
    fontWeight: 'normal',
  },
});

export default HomeStack;
