import { useEffect, useState } from 'react';
import {
  StyleSheet, Text, View
} from 'react-native';
import { useContextData } from '../contexts/Auth';
import { ChallengeSetDto } from '../dto/challenges.dto';
import { getChallengeSetsByFirebaseUid } from '../services/challenges.service';
import CustomStyles from '../styles/CustomStyles';

const SetsScreen = ({ navigation }: any) => {
  const { userData } = useContextData();

  const [ownedSets, setOwnedSets] = useState<ChallengeSetDto[]>([]);

  useEffect(() => {
    getChallengeSetsByFirebaseUid(userData!.firebaseUid).then((challengeSets: ChallengeSetDto[]) => {
      setOwnedSets(challengeSets);
    }).catch((err: any) => console.log(err))

    return () => {
      setOwnedSets([]);
    }
  }, [])



  // const showToast = (toastTypes: toastTypes) => {
  //   Toast.show({
  //     type: toastTypes.type,
  //     text1: toastTypes.text,
  //   });
  // };

  return (
    <View style={CustomStyles.container}>
      <Text>Your sets</Text>
      {ownedSets.map((set: ChallengeSetDto) => {
        return (
          <Text>{set.name}</Text>
        )
      })}
      {/* <Text>Something new?</Text>
      {currentOffering && (
        <>
          <Text>{currentOffering.identifier}</Text>
          {currentOffering?.availablePackages.map((packagea: PurchasesPackage) => {
            return (
              <Text>{packagea.product.title}</Text>
            )
          })}
        </>
      )} */}
    </View>
  );
};

const styles = StyleSheet.create({

});

export default SetsScreen;
