import { logEvent } from 'firebase/analytics';
import { Image, Modal, Pressable, StyleSheet, Text, View } from 'react-native';
import { EventRegister } from 'react-native-event-listeners';
import Toast from 'react-native-toast-message';
import { ChallengeWithLevel } from '../../dto/challenges.dto';
import { Member } from '../../dto/member.dto';
import { ToastTypes } from '../../dto/toast.dto';
import { analytics } from '../../firebase/config';
import { deleteChallengeHistory, unholdChallenge, updateChallengeHistory } from '../../services/challenge_history.service';
import Colors from '../../styles/Colors';

interface Props {
  user: Member;
  challengeViewVisible: boolean;
  shownChallenge: ChallengeWithLevel;
  adminView?: boolean;
  onCloseModal: () => void;
}

const ChallengeView = (props: Props) => {
  const showToast = (toastTypes: ToastTypes) => {
    Toast.show({
      type: toastTypes.type,
      text1: toastTypes.text,
    });
  };

  const user: Member = props.user;
  const shownChallenge: ChallengeWithLevel = props.shownChallenge;
  const challengeViewVisible: boolean = props.challengeViewVisible;
  const adminView: boolean | undefined = props.adminView;

  const unholdChallengeAction = async () => {
    await unholdChallenge(user.ID).then(res => {
      if (res.Hold) {
        showToast({
          type: 'error',
          text: 'Could not unhold challenge',
        });
      } else {
        logEvent(analytics, 'challenge_unhold');
        showToast({
          type: 'success',
          text: 'Challenge done. Please ask the scorer to validate the challenge.',
        });
      }
    }).catch(() => {
      showToast({
        type: 'error',
        text: 'Could not unhold challenge',
      });
    })

    EventRegister.emit('loadMember');
    props.onCloseModal()
  };

  const updateChallengeHistoryAndCloseModal = (success: boolean) => {
    updateChallengeHistory(user.ID, success, shownChallenge.challenge.ID)
      .then(() => {
        EventRegister.emit('loadMember');

        logEvent(analytics, 'challenge_done',
          {
            challenge_id: shownChallenge.challenge.ID,
            challenge_description: shownChallenge.challenge.description,
            success: success
          }
        );

        if (success) {
          showToast({
            type: 'success',
            text: `Der Member hat ${shownChallenge.points_win} Punkte bekommen`,
          });
        } else {
          showToast({
            type: 'info',
            text: `Der Member hat ${shownChallenge.points_lose} Punkte verloren`,
          });
        }
        props.onCloseModal();
      })
      .catch(() =>
        showToast({
          type: 'error',
          text: 'Irgendetwas ist schief gelaufen',
        })
      );
  };

  const deleteChallengeHistoryAction = () => {
    deleteChallengeHistory(user.ID).then(() => {
      logEvent(analytics, 'challenge_delete');
      EventRegister.emit('loadMember');
      showToast({
        type: 'success',
        text: 'Challenge history deleted',
      });
      props.onCloseModal();
    }
    ).catch(() =>
      showToast({
        type: 'error',
        text: 'Could not delete challenge history',
      })
    );
  }

  const reloadOnHold = () => {
    EventRegister.emit('loadMember');
    props.onCloseModal();
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={challengeViewVisible}
    >
      <View style={styles.challengeViewContainer}>
        <View style={styles.challengeView}>
          <View style={styles.challengeViewTitleContainer}>
            {adminView ? (
              <Text
                numberOfLines={1}
                adjustsFontSizeToFit
                style={styles.challengeViewTitle}>{user.username} Challenge</Text>
            ) : (
                <Text
                  numberOfLines={1}
                  adjustsFontSizeToFit
                  style={styles.challengeViewTitle}>Deine Challenge</Text>
            )}
          </View>

          <Image
            source={require('../../assets/challenge-icon.png')}
            style={styles.challengeImage}
          />

          <View style={styles.challengeDetailsView}>
            <Text style={styles.challengeDescription}>
              {shownChallenge.challenge.description}
            </Text>

            <Text style={styles.challengeLevel}>
              Level {shownChallenge.challenge.levelId}
            </Text>
          </View>
          <View style={styles.challengeButtonContainer}>
            {adminView ? (
              <>
                <Pressable
                  onPress={() => updateChallengeHistoryAndCloseModal(false)}
                  style={[
                    styles.challengeButton,
                    { backgroundColor: "#FF5654" },
                  ]}
                >
                  <Image
                    source={require('../../assets/cross-icon.png')}
                    style={{ height: 30, width: 30 }}
                  />
                </Pressable>

                <Pressable
                  onPress={() => deleteChallengeHistoryAction()}
                  style={[
                    styles.challengeButton,
                    { backgroundColor: '#F98D50', alignSelf: 'flex-end', width: 70, height: 70, marginBottom: 10 },
                  ]}
                >
                  <Image
                    source={require('../../assets/trashcan.png')}
                    style={{ height: 28, width: 28 }}
                  />
                </Pressable>

                <Pressable
                  onPress={() => updateChallengeHistoryAndCloseModal(true)}
                  style={[
                    styles.challengeButton,
                    { backgroundColor: Colors.primaryColor },
                  ]}
                >
                  <Image
                    source={require('../../assets/check-icon.png')}
                    style={{ height: 30, width: 30 }}
                  />
                </Pressable>
              </>
            ) : (
              <>
                <Pressable
                  onPress={() => reloadOnHold()}
                  style={[
                    styles.challengeButton,
                    { backgroundColor: "#FF5654" },
                  ]}
                >
                  <Image
                      source={require('../../assets/Ellipse.png')}
                      style={{ height: 35, width: 35 }}
                  />
                  <Text
                    style={{
                      position: 'absolute',
                        bottom: 6,
                      color: Colors.backgroundColor,
                    }}
                  >
                    später
                  </Text>
                </Pressable>

                <Pressable
                  onPress={() => unholdChallengeAction()}
                  style={[
                    styles.challengeButton,
                    { backgroundColor: Colors.primaryColor },
                  ]}
                >
                  <Image
                      source={require('../../assets/arrowLeft.png')}
                      style={{ height: 42, width: 42 }}
                  />
                  <Text
                    style={{
                      position: 'absolute',
                        bottom: 6,
                      color: Colors.backgroundColor,
                    }}
                  >
                    weiter
                  </Text>
                </Pressable>
              </>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  challengeViewContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.primaryColor,
  },
  challengeView: {
    height: '90%',
    width: '95%',
    backgroundColor: Colors.backgroundColor,
    borderRadius: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.5,
    shadowRadius: 8,
    elevation: 10,

    alignItems: 'center',
  },
  challengeViewTitleContainer: {
    top: 40,
    height: 'auto',
    width: '100%',
    zIndex: 1,
  },
  challengeViewTitle: {
    textAlign: 'center',
    fontSize: 30,
    fontWeight: '900',
    letterSpacing: 2,
    color: Colors.primaryColor,
  },
  challengeImage: {
    top: 60,
    height: 130,
    width: 130,
    resizeMode: 'cover',
  },
  challengeDetailsView: {
    top: 90,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 20,
    width: '95%',
    height: 200,
    backgroundColor: Colors.primaryColor,
    borderColor: Colors.primaryColor,
  },
  challengeDescription: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 10,
    padding: 10,
    textAlign: 'center',
    color: Colors.backgroundColor,
  },
  challengeLevel: {
    padding: 10,
    fontSize: 18,
    fontWeight: '500',
    color: Colors.backgroundColor,
  },
  challengeButtonContainer: {
    position: 'absolute',
    width: '100%',
    height: 120,
    bottom: 0,
    justifyContent: 'space-evenly',
    flexDirection: 'row',
  },
  challengeButton: {
    width: 90,
    height: 90,
    borderRadius: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ChallengeView;
