// @ts-ignore
import { LENV, REACT_ANDROID_EMU, REACT_APP_DEVELOP, REACT_APP_LOCAL, REACT_APP_PR, REACT_APP_PROD } from '@env';

export enum Environment {
  local = "local",
  pr = "pr",
  develop = "develop",
  prod = "prod"
}

const loadEnvironment = (): string => { 
  // @ts-ignore
  const processEnv = process.env.ENV;

  if (processEnv.length > 0) {
    return processEnv;
  } else {
    return LENV;
  }
}

const getEnvironmentUrl = (): string => {
  const env = loadEnvironment()

  switch (env) {
    case 'local':
      return `http://192.168.2.86${REACT_APP_LOCAL}`;
    case 'pr':
      return REACT_APP_PR;
    case 'develop':
      return REACT_APP_DEVELOP;
    case 'prod':
      return REACT_APP_PROD;
    default:
      throw new Error(`Unknown environment: ${LENV}`);
  }
}

const GetEnvironment = (): Environment | undefined => {
  const env = loadEnvironment()

  switch (env) {
    case 'local':
      return Environment.local;
    case 'pr':
      return Environment.pr;
    case 'develop':
      return Environment.develop;
    case 'prod':
      return Environment.prod;
    default:
      throw new Error(`Unknown environment: ${LENV}`);
  }
}

const EnvironmentApiUrl = (): string => {
  // @ts-ignore
  if (process.env.ANDROID_EMULATOR) {
    return REACT_ANDROID_EMU
  }

  const url = getEnvironmentUrl()

  console.log("URL: " + url)

  return url;
};

export { EnvironmentApiUrl, GetEnvironment };
