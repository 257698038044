import { Avatar } from "@rneui/themed";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import Colors from "../../styles/Colors";
import CustomStyles from "../../styles/CustomStyles";

const GuestHomeItem = (props: any) => {
  const { name } = props;

  return (
    <View style={[styles.container, CustomStyles.shadowBottom]}>
      <View
        style={[
          styles.container,
          {
            flexDirection: "row",

            backgroundColor: Colors.primaryColor,
          },
        ]}
      >
        <View style={styles.imageContainer}>
          <Avatar
            containerStyle={{
              height: 80,
              width: 80,
              borderRadius: 40,
              backgroundColor: "white",
            }}
            titleStyle={{ fontSize: 30 }}
            rounded
            activeOpacity={0.7}
          />
        </View>
        <Text style={[styles.text, { fontWeight: "bold" }]}>{name}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 100,
    width: 312,
    alignSelf: "center",
    borderRadius: 16,
    alignItems: "center",
  },
  imageContainer: {
    flex: 1,
    marginLeft: 10,
  },
  text: {
    flex: 2,
    fontSize: 30,
    color: Colors.backgroundColor,
  },
});

export default GuestHomeItem;
