import React from 'react';
import {
  Dimensions,
  KeyboardAvoidingView,
  Modal,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import Colors from '../../styles/Colors';

const ValidateMemberModal = (props: any) => {
  const { name, closeActiveModal, value, modalVisible } = props;

  const closeModal = () => {
    closeActiveModal();
  };

  return (
    <Modal animationType="fade" transparent={true} visible={modalVisible}>
      <Pressable
        style={styles.backgroundFaded}
        onPress={() => closeModal()}
      ></Pressable>
      <KeyboardAvoidingView
        style={styles.modalView}
        behavior={Platform.OS === 'ios' ? 'position' : 'position'}
      >
        <View style={styles.keyboardView}>
          <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
            <Text style={{ color: Colors.backgroundColor, fontSize: 26, textAlign: "center" }}>
            {name} möchte deinem Haus beitreten! Akzeptieren?
          </Text>

          </View>
          <View style={{ flex: 0.7, flexDirection: 'row', alignItems: "flex-end" }}>
            <Pressable
              style={styles.button}
              onPress={() => value(true)}>
              <Text style={{ fontSize: 20, fontWeight: "700" }}>Ja</Text>
            </Pressable>
            <Pressable
              style={styles.button}
              onPress={() => value(false)}>
              <Text style={{ fontSize: 20, fontWeight: "700" }}>Nein</Text>
            </Pressable>
          </View>
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  keyboardView: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: Colors.primaryColor,
    borderRadius: 20,

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    width: "40%",
    height: 50,
    backgroundColor: Colors.fontColor,
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    margin: 10,
    marginBottom: 30
  },
  backgroundFaded: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'grey',
    opacity: 0.5,
  },
  modalView: {
    position: 'absolute',
    top: Dimensions.get('screen').height * 0.3,
    alignSelf: 'center',
    width: '80%',
    height: 300,
  },
});

export default ValidateMemberModal;
