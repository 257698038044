import { friendscoreAPI } from './environment/axios.service';

let URI = '/anonSignUp';

export async function getAnonUserJwt(uid: string): Promise<string> {
  try {
    let res = await friendscoreAPI.get(URI + `/${uid}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
