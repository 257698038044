import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, Text, View } from 'react-native';
import ProfileScreen from '../screens/ProfileScreen';
import SetsScreen from '../screens/SetsScreen';
import SettingsScreen from '../screens/SettingsScreen';
import Colors from '../styles/Colors';
import CustomStyles from '../styles/CustomStyles';

const Stack = createStackNavigator();

const SettingStack = (props: any) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: CustomStyles.container.backgroundColor,
          shadowOpacity: 0,
          elevation: 0,
        },
        headerTitleStyle: {
          fontWeight: CustomStyles.header.fontWeight,
          fontSize: CustomStyles.header.fontSize,
        },
        headerTitleAlign: 'left',
        headerBackTitleVisible: false,
        headerTintColor: Colors.primaryColor,
        headerRightContainerStyle: {
          paddingRight: 12,
        },
      }}
    >
      <Stack.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          headerTitle: () => (
            <View style={{ flex: 1, justifyContent: "center" }}>
              <Text style={styles.mainTitle}>Einstellungen</Text>
            </View>
          ),
        }}
      />
      <Stack.Screen
        name="ProfileScreen"
        component={ProfileScreen}
        options={{
          headerLeftContainerStyle: {
            marginRight: -20,
          },
          headerTitle: () => (
            <View style={{ flex: 1, justifyContent: "center" }}>
              <Text style={styles.mainTitle}>Profile</Text>
            </View>
          ),
        }}
      />
      <Stack.Screen
        name="SetsScreen"
        component={SetsScreen}
        options={{
          headerLeftContainerStyle: {
            marginRight: -20,
          },
          headerTitle: () => (
            <View style={{ flex: 1, justifyContent: "center" }}>
              <Text style={styles.mainTitle}>Sets</Text>
            </View>
          ),
        }}
      />
      {/*
      <Stack.Screen
        name="HomeDetailScreen"
        component={HomeDetailScreen}
        options={({ route }: any) => ({
          title: route.params.title,
          headerTitleStyle: {
            fontSize: 26,
          },
        })}
      />
      <Stack.Screen
        name="HomeDetailScreen"
        component={HomeDetailScreen}
        options={({ route }: any) => ({
          title: route.params.title,
          headerTitleStyle: {
            fontSize: 26,
          },
        })}
      /> */}
    </Stack.Navigator>
  );
};

const styles = StyleSheet.create({
  mainTitle: {
    color: Colors.primaryColor,
    fontSize: 30,
  },
});

export default SettingStack;
