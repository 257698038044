import { logEvent } from "firebase/analytics";
import { useEffect, useState } from "react";
import { Dimensions, Modal, Pressable, StyleSheet, Text, View } from "react-native";
import { ChallengeSetDto, ChallengeWithLevel } from "../../dto/challenges.dto";
import { Member } from "../../dto/member.dto";
import { analytics } from "../../firebase/config";
import { getChallengeBySetId } from "../../services/challenges.service";
import { getChallengeSets } from "../../services/challenge_set.service";
import { updateMember } from "../../services/members.service";
import Colors from "../../styles/Colors";
import ChallengeView from "./ChallengeView";

const ChooseChallengeSetModal = (props: any) => {
  const [showChallenge, SetShowChallenge] = useState(false);
  const [shownChallenge, SetShownChallenge] = useState<ChallengeWithLevel>();

  const member: Member = props.member;

  useEffect(() => {
    if (shownChallenge?.challenge.ID) {
      SetShowChallenge(true);
    }
  }, [shownChallenge]);

  const calculateHeight = (): (number | string) => {
    let height: number | string = 120 * getChallengeSets().length;

    if (height > Dimensions.get("window").height) {
      height = "100%"
    }

    return height;
  }

  const showChallengeAndUpdateMember = (challengeSet: ChallengeSetDto): any => {
    logEvent(analytics, 'challenge_set_selected', {
      challenge_set_id: challengeSet.ID
    });


    return updateMember(member.ID).then(() => {
      getChallengeBySetId(challengeSet.ID, member.ID).then((challenge) => {
        SetShownChallenge(challenge);
      });
    });
  };

  const closeModals = () => {
    SetShowChallenge(false);
    props.onCloseModal();
  };

  const ChallengeSet = (props: any) => {
    const challengeSet = props.challengeSet;

    return (
      <View style={styles.challengeSetContainer}>
        <Pressable
          style={styles.challengeSetButton}
          onPress={() => showChallengeAndUpdateMember(challengeSet)}
        >
          <Text style={styles.setName}>{challengeSet.name}</Text>
          <Text style={styles.setDescription}>{challengeSet.description}</Text>
        </Pressable>
      </View>
    );
  };

  return (
    <Modal animationType="fade" transparent={true} visible={props.modalVisible}>
      {!showChallenge && (
        <>
          <Pressable
            style={styles.backgroundFaded}
            onPress={() => props.onCloseModal()}
          ></Pressable>

          <View style={[styles.modalView, { height: calculateHeight() }]}>
            {getChallengeSets().map((challengeSet, index) => {
              return (
                <ChallengeSet
                  challengeSet={challengeSet}
                  key={index}
                ></ChallengeSet>
              );
            })}
          </View>
          {/* FIXME add routing to store later on */}
        </>
      )}
      {showChallenge && (
        <ChallengeView
          challengeViewVisible={showChallenge}
          user={member}
          shownChallenge={shownChallenge!}
          onCloseModal={() => closeModals()}
        />
      )}
    </Modal>
  );
};

const styles = StyleSheet.create({
  backgroundFaded: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: Colors.backgroundColor,
    opacity: 0.8,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  modalView: {
    alignSelf: "center",
    width: "80%",
    alignItems: "center",
    justifyContent: "center",
  },
  challengeSetContainer: {
    height: 100,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
  },
  challengeSetButton: {
    flex: 1,
    width: "100%",
    borderRadius: 20,
    paddingLeft: 20,
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    backgroundColor: Colors.primaryColor,
  },
  setName: {
    fontSize: 28,
  },
  setDescription: {
    fontSize: 16,
  },
});

export default ChooseChallengeSetModal;
