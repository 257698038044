import { logEvent } from 'firebase/analytics';
import { useState } from 'react';
import {
  Dimensions,
  KeyboardAvoidingView,
  Modal,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View
} from 'react-native';
import { EventRegister } from 'react-native-event-listeners';
import Toast from 'react-native-toast-message';
import { useContextData } from '../../contexts/Auth';
import { CreateHome } from '../../dto/homes.dto';
import { ToastTypes } from '../../dto/toast.dto';
import { analytics } from '../../firebase/config';
import { addNewHome } from '../../services/homes.service';
import Colors from '../../styles/Colors';

const AddHomeModal = (props: any) => {
  const { authData, userData } = useContextData();

  const [homename, SetHomename] = useState('');
  const [disableButton, SetDisableButton] = useState(false);

  const showToast = (toastTypes: ToastTypes) => {
    Toast.show({
      type: toastTypes.type,
      text1: toastTypes.text,
    });
  };

  const closeModal = () => {
    props.closeActiveModal();
  };

  const addHome = () => {
    SetDisableButton(true);
    let createHome: CreateHome = {
      firebaseUid: authData!.uid,
      iconURL: '',
      name: homename,
    };

    addNewHome(createHome)
      .then(() => {
        showToast({
          type: 'success',
          text: 'Willkommen',
        });

        logEvent(analytics, 'create_home');

        EventRegister.emit('loadHomes');
        SetHomename('');
        closeModal();
      })
      .catch(() => {
        showToast({
          type: 'error',
          text: 'Da hat etwas nicht funktioniert',
        });
        SetDisableButton(false);
      });
  };

  return (
    <Modal animationType="fade" transparent={true} visible={props.modalVisible}>
      <Pressable
        style={styles.backgroundFaded}
        onPress={() => closeModal()}
      ></Pressable>

      <KeyboardAvoidingView
        style={styles.modalView}
        behavior={Platform.OS === 'ios' ? 'position' : 'position'}
      >
        <View style={styles.keyboardView}>
          {userData?.mail ? (
            <>
              <TextInput
                style={styles.textInput}
                selectionColor={Colors.primaryColor}
                placeholderTextColor={Colors.primaryColor}
                placeholder="Hausname"
                textAlign="center"
                value={homename}
                onChangeText={SetHomename}
              ></TextInput>
              <Pressable
                disabled={disableButton}
                style={
                  disableButton || homename == ''
                    ? [styles.buttonClose, { opacity: 0.25 }]
                    : styles.buttonClose
                }
                onPress={() => addHome()}
              >
                <Text style={styles.buttonText}>Fertig</Text>
              </Pressable>
            </>
          ) : (
            <>
            <Text style={{ color: Colors.primaryColor, fontSize: 26, padding: 12, textAlign: "center" }}>
              Du musst angemeldet sein, um ein eigenes Haus hinzuzufügen
            </Text>
            <Pressable style={{
              width: "40%", 
              height: 40, 
              backgroundColor: Colors.primaryColor, 
              borderRadius: 20,
              alignItems: "center",
              justifyContent: "center",
              }} 
              onPress={() => closeModal()}>
                <Text style={{fontSize: 20, fontWeight: "700"}}>Ok</Text>
            </Pressable>
            </>
          )}
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  keyboardView: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: Colors.primaryColor,
    borderRadius: 20,

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  backgroundFaded: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'grey',
    opacity: 0.5,
  },
  modalView: {
    position: 'absolute',
    top: Dimensions.get('screen').height * 0.35,
    alignSelf: 'center',
    width: "80%",
    height: 200,
  },
  buttonText: {
    fontSize: 20,
    fontWeight: '600',
    color: Colors.backgroundColor,
  },
  buttonClose: {
    width: 150,
    height: 40,
    borderRadius: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 2,
    backgroundColor: Colors.fontColor,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textInput: {
    height: 40,
    width: '80%',
    backgroundColor: '#828282',
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'center',
    fontSize: 18,
    color: Colors.primaryColor,
  },
});

export default AddHomeModal;
