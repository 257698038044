
import axios from 'axios';
import { EnvironmentApiUrl } from './environmentHelper';
// import { EnvironmentApiUrl } from './environmentHelper';

// FIXME (AW) make apiKey configurable
export const friendscoreAPI = axios.create({
  // baseURL: "http://127.0.0.1:3000/test/v1",
  baseURL: EnvironmentApiUrl(),
  timeout: 10000,
  validateStatus: function (status) {
    return status >= 200 || status < 300 || status === 404; // default
  },
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    apk: "dkusqagibewyy81ndqnuavp7yzu5w6cg2bkcos2ng8opvxv6w2",
  },
});
