import { useState } from 'react';
import {
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View
} from 'react-native';
import Toast from 'react-native-toast-message';
import { useContextData } from '../contexts/Auth';
import { ToastTypes } from '../dto/toast.dto';
import Colors from '../styles/Colors';
import CustomStyles from '../styles/CustomStyles';
import theme from '../styles/theme';

const ForgotPasswordScreen = ({ navigation }: any) => {
  const auth = useContextData();

  const [mail, SetMail] = useState('');

  const [mailTouched, SetMailTouched] = useState(false);

  const showToast = (toastType: ToastTypes) => {
    Toast.show({
      type: toastType.type,
      text1: toastType.text,
    });
  };

  const isFormValid = () => {
    if (isMailValid()) {
      return true;
    }
    return false;
  };

  const isMailValid = () => {
    const expression =
      /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return expression.test(String(mail).toLowerCase());
  };

  const handleForgotPassword = async () => {
    Keyboard.dismiss();
    const response = await auth.resetPassword(mail);
    if (response.success) {
      showToast({
        type: 'success',
        text: 'reset mail sent',
      });
      navigation.navigate('LoginRegisterScreen');
    } else {
      showToast({
        type: 'error',
        text: response.error,
      });
    }

  };

  return (
    <Pressable
      style={CustomStyles.container}
      onPress={() => Keyboard.dismiss()}
    >
      <KeyboardAvoidingView
        style={styles.inputContainer}
        behavior={Platform.OS === 'ios' ? 'position' : 'height'}
      >
        <View style={styles.logoContainer}>
          <Image source={require('../assets/logo.png')} style={styles.icon} />
        </View>

        <Text style={{
          alignSelf: 'center',
          fontSize: 21,
          marginHorizontal: 10,
          textAlign: 'center',
          color: Colors.primaryColor
        }}>Enter the email address associated to your account.</Text>
        <Text style={{
          alignSelf: 'center',
          fontSize: 16,
          marginTop: 10,
          marginBottom: 20,
          marginHorizontal: 40,
          textAlign: 'center',
          color: Colors.primaryColor,
          opacity: 0.85
        }}>We will email you a link to reset your password.</Text>

        <TextInput
          autoComplete='email'
          style={
            !isMailValid() && mailTouched
              ? [
                styles.textInput,
                { borderWidth: 2, borderColor: theme.colors.error },
              ]
              : styles.textInput
          }
          placeholder="mail"
          textAlign="center"
          value={mail}
          onChangeText={SetMail}
          onTouchStart={() => SetMailTouched(true)}
        ></TextInput>
      </KeyboardAvoidingView>
      <View style={styles.buttonContainer}>
        <Pressable
          style={
            isFormValid()
              ? styles.loginButton
              : [styles.loginButton, { opacity: 0.25 }]
          }
          disabled={!isFormValid()}
          onPress={() => handleForgotPassword()}
        >
          <Text style={styles.loginText}>
            send
          </Text>
        </Pressable>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  icon: {
    height: 190,
    width: 190,
    resizeMode: 'cover',
  },
  logoContainer: {
    flex: 1.5,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 20,
  },
  inputContainer: {
    flex: 2,
    justifyContent: 'flex-end',
  },
  textInput: {
    height: 40,
    width: '80%',
    backgroundColor: Colors.primaryColor,
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 18,
    marginBottom: 15,
    alignSelf: 'center',
  },
  buttonContainer: {
    flex: 0.8,
    width: '80%',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  loginButton: {
    width: '50%',
    height: 40,
    backgroundColor: Colors.primaryColor,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    borderRadius: 10,
  },
  loginText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: Colors.backgroundColor,
  },
});

export default ForgotPasswordScreen;
